<template>
  <div class="d-flex">
    <b-button v-if="previous" variant="flat-primary" @click="$emit('click:previous')">
      <font-awesome-icon icon="arrow-left" />
      {{ $t('common.previous') }}
    </b-button>

    <div class="ml-auto">
      <b-button v-if="save" type="submit" variant="flat-primary" :disabled="disabled" @click="$emit('click:save')">
        <b-spinner v-if="saveLoader" small />
        <font-awesome-icon icon="save" />
        {{ $t('action.save') }}
      </b-button>

      <b-button
        v-if="saveNext"
        type="submit"
        variant="flat-primary"
        :disabled="disabled"
        @click="$emit('click:save-next')"
      >
        {{ $t('action.save_and_next') }}
        <b-spinner v-if="saveLoader" small />
        <font-awesome-icon icon="arrow-right" />
      </b-button>

      <b-button v-if="next" variant="flat-primary" @click="$emit('click:next')">
        {{ $t('common.next') }}
        <font-awesome-icon icon="arrow-right" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    saveLoader: {
      type: Boolean,
      default: false,
    },
    previous: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Boolean,
      default: false,
    },
    saveNext: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {}
  },
})
</script>
