var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"offer-deletion-soft"}},[_c('app-sidebar-form',{attrs:{"form-name":"offer-deletion-soft","edition":_vm.offerDeletion,"edition-mode-title":_vm.offerDeletionMode,"disabled":_vm.offerLoading,"title":_vm.$tc('offer.title', 1),"entity":_vm.offer},on:{"update:edition":function($event){return _vm.$emit('editionUpdated', $event)},"update:entity":function($event){return _vm.$emit('offerUpdated', $event)}},scopedSlots:_vm._u([{key:"fields",fn:function(data){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":((_vm.$t('common.suggested')) + " " + (_vm.$tc('offer.title', 0))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"validation-required",attrs:{"label":((_vm.$t('common.suggested')) + " " + (_vm.$tc('offer.title', 0))),"label-for":"offer-deletion-soft-suggested-offers-ids"}},[_c('b-overlay',{attrs:{"show":_vm.offerLoading || _vm.offersNameLoading}},[_c('v-select',{staticClass:"select-size-md",attrs:{"input-id":"offer-deletion-soft-suggested-offers-ids","options":_vm.offerNameActive,"reduce":function (name) { return name.id; },"label":"reference","placeholder":((_vm.$t('common.suggested')) + " " + (_vm.$tc('offer.title', 0))),"clearable":false,"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"selected-option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.no_option_found'))+" ")]},proxy:true}],null,true),model:{value:(data.item.suggestedOffersIds),callback:function ($$v) {_vm.$set(data.item, "suggestedOffersIds", $$v)},expression:"data.item.suggestedOffersIds"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.note'))+" "+_vm._s(_vm.$t('offer.suggested_offer_note')))])]),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":((_vm.$t('common.default')) + " " + (_vm.$tc('offer.title', 0))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"validation-required",attrs:{"label":((_vm.$t('common.default')) + " " + (_vm.$tc('offer.title', 0))),"label-for":"offer-deletion-soft-default-offer-id"}},[_c('b-overlay',{attrs:{"show":_vm.offerLoading || _vm.offersNameLoading}},[_c('v-select',{staticClass:"select-size-md",attrs:{"input-id":"offer-deletion-soft-default-offer-id","options":_vm.offerNameActive,"reduce":function (name) { return name.id; },"label":"reference","placeholder":((_vm.$t('common.default')) + " " + (_vm.$tc('offer.title', 0))),"clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"selected-option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.no_option_found'))+" ")]},proxy:true}],null,true),model:{value:(data.item.defaultOfferId),callback:function ($$v) {_vm.$set(data.item, "defaultOfferId", $$v)},expression:"data.item.defaultOfferId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.note'))+" "+_vm._s(_vm.$t('offer.default_offer_note')))])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }