<template>
  <div :id="`${tableName}-table-bottom`">
    <b-row>
      <!-- Items -->
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
        <app-data-table-entries-display :table-name="tableName" :page="page" :per-page="perPage" :total-count="totalCount" :items-length="itemsLength" />
      </b-col>
      <!-- Pagination -->
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <div :id="`${tableName}-table-pagination`">
          <b-pagination
            :value="page"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            align="end"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="$emit('pagination', $event)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppDataTableEntriesDisplay from '@/components/AppDataTableEntriesDisplay.vue'

export default {
  name: 'AppDataTableBottom',

  components: {
    AppDataTableEntriesDisplay,
  },

  props: {
    tableName: {
      type: String,
      required: true,
      default: '',
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
  },
}
</script>
