<template>
  <div id="offer-deletion-soft">
    <app-sidebar-form
      form-name="offer-deletion-soft"
      :edition="offerDeletion"
      :edition-mode-title="offerDeletionMode"
      :disabled="offerLoading"
      :title="$tc('offer.title', 1)"
      :entity="offer"
      @update:edition="$emit('editionUpdated', $event)"
      @update:entity="$emit('offerUpdated', $event)"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- suggestedOffersIds -->
            <validation-provider #default="{ errors }" :name="`${$t('common.suggested')} ${$tc('offer.title', 0)}`" rules="required">
              <b-form-group
                :label="`${$t('common.suggested')} ${$tc('offer.title', 0)}`"
                label-for="offer-deletion-soft-suggested-offers-ids"
                class="validation-required"
              >
                <b-overlay :show="offerLoading || offersNameLoading">
                  <v-select
                    v-model="data.item.suggestedOffersIds"
                    input-id="offer-deletion-soft-suggested-offers-ids"
                    :options="offerNameActive"
                    :reduce="name => name.id"
                    label="reference"
                    :placeholder="`${$t('common.suggested')} ${$tc('offer.title', 0)}`"
                    :clearable="false"
                    class="select-size-md"
                    multiple
                  >
                    <template #option="{ reference }">
                      {{ reference | trans }}
                    </template>
                    <template #selected-option="{ reference }">
                      {{ reference | trans }}
                    </template>
                    <template #no-options> {{ $t('common.no_option_found') }} </template>
                  </v-select>
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mb-2">
            <span>{{ $t('common.note') }} {{ $t('offer.suggested_offer_note') }}</span>
          </b-col>
          <b-col cols="12">
            <!-- defaultOfferId -->
            <validation-provider #default="{ errors }" :name="`${$t('common.default')} ${$tc('offer.title', 0)}`" rules="required">
              <b-form-group
                :label="`${$t('common.default')} ${$tc('offer.title', 0)}`"
                label-for="offer-deletion-soft-default-offer-id"
                class="validation-required"
              >
                <b-overlay :show="offerLoading || offersNameLoading">
                  <v-select
                    v-model="data.item.defaultOfferId"
                    input-id="offer-deletion-soft-default-offer-id"
                    :options="offerNameActive"
                    :reduce="name => name.id"
                    label="reference"
                    :placeholder="`${$t('common.default')} ${$tc('offer.title', 0)}`"
                    :clearable="false"
                    class="select-size-md"
                  >
                    <template #option="{ reference }">
                      {{ reference | trans }}
                    </template>
                    <template #selected-option="{ reference }">
                      {{ reference | trans }}
                    </template>
                    <template #no-options> {{ $t('common.no_option_found') }} </template>
                  </v-select>
                </b-overlay>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mb-2">
            <span>{{ $t('common.note') }} {{ $t('offer.default_offer_note') }}</span>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default {
  name: 'OfferDeletionSoft',

  components: {
    AppSidebarForm,
  },

  props: {
    offerDeletion: {
      type: Boolean,
      required: true,
    },
    offerDeletionMode: {
      type: String,
      default: null,
    },
    offer: {
      type: Object,
      default: () => ({}),
    },
    offerLoading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('offer', ['offersNameLoading']),
    ...mapGetters('offer', ['offerNameActive']),
  },

  mounted() {
    this.fetchoffersName()
  },

  methods: { ...mapActions('offer', ['fetchoffersName']) },
}
</script>
