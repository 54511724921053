<template>
  <div id="offer-subscription-descriptions">
    <validation-observer ref="offer-subscription-descriptions-observer" v-slot="{ valid }" slim>
      <b-table
        ref="offer-subscription-descriptions-table"
        :busy="offerSubscriptionLoading"
        :current-page="page"
        :fields="offerSubscriptionDescriptionsFields"
        :items="descriptions.edited"
        :per-page="numberOfItemsPerPage"
        :show-empty="!description"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-dropdown-action"
        hover
        primary-key="id"
        responsive
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- EMPTY -->
        <template #empty>
          <div v-if="offerEdition" class="text-center text-primary my-2">
            <b-overlay :show="offerSubscriptionLoading">
              <b-button v-if="$can('OPERATOR_SUBSCRIPTION_ADD')" id="offer-description-add" size="md" variant="outline-primary" @click="addDescription()">
                <span class="text-nowrap">
                  <feather-icon class="mr-25" icon="PlusIcon" />
                  {{ $t('offer.subscription.add_new_description') }}
                </span>
              </b-button>
            </b-overlay>
          </div>
          <div v-else class="text-center my-2">
            <span class="text-nowrap">
              {{ $t('common.no_record_found') }}
            </span>
          </div>
        </template>
        <!-- TOP ROW -->
        <template v-if="description" #top-row>
          <!-- CELL -->
          <!-- new description -->
          <b-td class="top-row-unerline">
            <app-form-text-input-itn
              v-model="description.description"
              :default-lang="$store.state.auth.defaultLang"
              :label="$t('offer.subscription.description')"
              :languages="$store.state.auth.activelang"
              :loading="offerSubscriptionLoading"
              :show-label="false"
              form-name="offer-subscription-descriptions-new"
              tool-tip-errors
            />
          </b-td>
          <!-- action -->
          <b-td align="right">
            <app-data-table-actions
              :show-cancel="true"
              :show-dropdown="false"
              :show-save="valid"
              table-name="offer-subscription-descriptions-new"
              @cancel="clearDescription()"
              @save="saveDescription(description)"
            />
          </b-td>
        </template>
        <!-- CELL -->
        <!-- descriptions -->
        <template #cell(description)="data">
          <app-form-text-input-itn
            v-if="offerEdition"
            v-model="data.item.description"
            :default-lang="$store.state.auth.defaultLang"
            :disabled="descriptions.touched && descriptions.touched !== data.item.id"
            :form-name="`offer-subscription-descriptions-${data.id}`"
            :label="$t('offer.subscription.description')"
            :languages="$store.state.auth.activelang"
            :loading="offerSubscriptionLoading"
            :show-label="false"
            tool-tip-errors
            @input-text="descriptions.touched = data.item.id"
          />
          <span v-else>{{ data.item.description[lang] }}</span>
        </template>
        <!-- action -->
        <template #cell(actions)="data">
          <div v-show="descriptions.touched === data.item.id" align="right">
            <app-data-table-actions
              :show-cancel="true"
              :show-dropdown="false"
              :show-save="valid"
              table-name="offer-subscription-descriptions"
              @cancel="clearDescription()"
              @save="saveDescription(data.item)"
            />
          </div>
          <app-data-table-actions
            v-show="!descriptions.touched && !description && offerEdition"
            :show-add="$can('OPERATOR_SUBSCRIPTION_ADD')"
            :show-delete="$can('OPERATOR_SUBSCRIPTION_DELETE')"
            :show-duplicate="$can('OPERATOR_SUBSCRIPTION_ADD')"
            :show-edit="false"
            :show-show="false"
            table-name="offer-subscription-descriptions"
            @add="addDescription()"
            @delete="deleteDescription(data.index)"
            @duplicate="duplicateDescription(data.item)"
          />
        </template>
      </b-table>
      <!--PAGINATION-->
      <div class="mx-2 mb-2">
        <app-data-table-bottom
          :page="page"
          :per-page="numberOfItemsPerPage"
          :total-count="totalItems"
          table-name="offer-subscription-descriptions"
          @pagination="page = $event"
        />
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormTextInputItn from '@/components/AppFormTextInputItn.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

export default {
  name: 'OfferPartSubscriptionDescriptions',

  components: {
    AppDataTableBottom,
    AppFormTextInputItn,
    AppDataTableActions,
  },

  props: {
    offerEdition: {
      type: Boolean,
      required: true,
    },
    offerSubscriptionLoading: {
      type: Boolean,
      required: true,
    },
    offerDescriptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      descriptions: { edited: [], old: [], touched: null },
      description: null,

      numberOfItemsPerPage: 5,
      page: 1,
      sortBy: 'description',
      isSortDirDesc: false,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang', 'dataTable']),

    offerSubscriptionDescriptionsFields() {
      if (this.offerEdition) {
        return [
          {
            key: 'description',
            label: this.$t('offer.subscription.descriptions'),
            thClass: 'w-100',
            sortable: true,
          },
          {
            key: 'actions',
            label: this.$tc('action.title', 2),
            thClass: 'text-right',
            tdClass: 'text-right',
          },
        ]
      }

      return [
        {
          key: 'description',
          label: this.$t('offer.subscription.descriptions'),
        },
      ]
    },

    totalItems() {
      return this.descriptions.edited ? this.descriptions.edited.length : 0
    },
  },

  watch: {
    offerDescriptions: {
      handler() {
        this.getDescription()
      },
      deep: true,
    },
  },

  methods: {
    getDescription() {
      this.descriptions.edited = this._cloneDeep(this.offerDescriptions)
      this.descriptions.old = this._cloneDeep(this.descriptions.edited)
    },

    saveDescription(description) {
      if (description.id) {
        this.$emit('updateDescriptions', this.descriptions.edited)
        this.clearDescription()
      } else {
        this.descriptions.edited.unshift(description)
        this.$emit('updateDescriptions', this.descriptions.edited)
        this.clearDescription()
      }
    },

    addDescription() {
      this.descriptions.touched = '0'
      this.description = { id: null, description: {} }
    },

    duplicateDescription(descriptionToCopy) {
      this.description = this._cloneDeep(descriptionToCopy)
      this.description.id = null
      Object.keys(this.description.description).forEach(lang => {
        this.description.description[lang] += '_copy'
      })
    },

    deleteDescription(index) {
      this.descriptions.edited.splice(index, 1)
      this.$emit('updateDescriptions', this.descriptions.edited)
    },

    clearDescription() {
      this.description = null
      this.descriptions.touched = null
      this.descriptions.edited = this._cloneDeep(this.descriptions.old)
      this.$refs['offer-subscription-descriptions-observer'].reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.top-row-unerline {
  border-bottom: 2px solid $primary !important;
}
</style>
