<template>
  <div :id="`${tableName}-table-entries-display`">
    <span class="text-muted">
      {{ dataMeta.from }} {{ $t('pagination.entries_to') }} {{ dataMeta.to }} {{ $t('pagination.entries_of') }}
      {{ totalCount }} {{ $tc('pagination.entries', totalCount) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppDataTableEntriesDisplay',

  props: {
    tableName: {
      type: String,
      required: false,
      default: '',
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    dataMeta() {
      let from = this.perPage * (this.page - 1) + 1
      let to = this.perPage * (this.page - 1) + this.perPage
      to >= this.totalCount && (to = this.totalCount)
      this.totalCount === 0 && (from = 0)
      return {
        to,
        from,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
