/* eslint-disable no-param-reassign */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'

export default {
  methods: {
    dropzoneMounted(fileUrl, ref, index) {
      if (fileUrl) {
        this.$nextTick(() => {
          let myDropzone = null
          if (index) {
            myDropzone = this.$refs[ref][index]
          } else {
            myDropzone = this.$refs[ref]
          }
          const file = {
            size: 200,
            type: 'image/*',
          }
          const url = fileUrl
          myDropzone.manuallyAddFile(file, url)
        })
      }
    },
    removeFile(fileUrl, imgPath, element, nameDelete) {
      fileUrl = ''
      imgPath = ''
      element[nameDelete] = true
    },
    sendingFile(file, element, fileNameId, loader, nameDelete, fileUrl, ref) {
      let reference = this.$refs[ref]
      if (Array.isArray(reference)) {
        // eslint-disable-next-line prefer-destructuring
        reference = reference[0]
      }

      if (file.size > reference.dropzone.options.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reference.removeFile(file)
        return
      }

      if (!file.manuallyAdded) {
        this.$data[loader] = false
        element[nameDelete] = false
        const formData = new FormData()
        formData.append('temporary_file', file)
        postFileRequest(formData)
          .then(response => {
            this.$data[loader] = true
            element[fileNameId] = response.data.id
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('upload.image.success'),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.$data[loader] = true
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('upload.image.failed'),
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else if (fileUrl) {
        file.previewElement.addEventListener('click', () => {
          window.open(element[fileUrl])
        })
      }
    },
    alertUploadIsProcessing(loader) {
      let message = ''
      if (!this.$data[loader]) {
        message += this.$t('upload.processing')
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    alertSaveSuccess() {
      this.alert(this.$t('alert.save.success'))
    },
  },
}
