<template>
  <div id="offer-typologies">
    <div class="m-2">
      <h3 class="mb-2">
        {{ $tc('offer.typology.title', 0) }}
      </h3>
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="1">
          <b-form-select
            v-model="numberOfItemsPerPage"
            :disabled="offerTypologiesLoading || organizationTypologiesLoading"
            :options="dataTable.parPageOption"
            class="per-page-selector"
          />
        </b-col>
        <!-- search-->
        <b-col v-if="offerEdition" class="d-flex align-items-center justify-content-start mb-1 mb-lg-0" cols="12" lg="7"> </b-col>
        <!-- action -->
        <b-col v-if="offerEdition" class="d-flex align-items-center justify-content-end" cols="12" lg="4">
          <b-button class="mr-1" variant="outline-primary" @click="selectAllRows">
            {{ $t('action.select_all') }}
          </b-button>
          <b-button variant="outline-primary" @click="clearSelected"> {{ $t('action.clear_selected') }}</b-button>
        </b-col>
      </b-row>
    </div>
    <!-- TABLE -->
    <b-table
      ref="offer-typologies-table"
      :busy="offerTypologiesLoading || organizationTypologiesLoading"
      :current-page="page"
      :empty-text="$t('common.no_record_found')"
      :fields="offerTypologiesFields"
      :items="typologies"
      :per-page="numberOfItemsPerPage"
      :selectable="offerEdition"
      :show-empty="offerTypologies.edited.length ? true : false"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :tbody-tr-class="tbodyRowClass"
      class="position-relative table-dropdown-action"
      hover
      primary-key="id"
      responsive
      @row-clicked="rowClicked"
    >
      <!-- LOADING -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <!-- TOP ROW -->
      <!-- mainTypology -->
      <template #top-row>
        <b-td v-if="$store.getters['app/lgAndDown']"> </b-td>
        <b-th class="top-row-unerline">{{ mainTypology.name | trans }} </b-th>
        <b-td>{{ mainTypology.typology }} </b-td>
        <b-td v-if="$store.getters['app/lgAndUp']">
          <b-form-textarea
            id="offer-typologies-description"
            :placeholder="$t('common.description')"
            :value="$options.filters.trans(mainTypology.offerDescription)"
            class="hide-description-scroll"
            max-rows="4"
            no-resize
            plaintext
            rows="4"
          >
          </b-form-textarea>
        </b-td>
        <b-td v-if="offerEdition" align="center">
          <span class="font-weight-bold text-primary">{{ $t('offer.typology.main_typology') }} </span>
        </b-td>
      </template>
      <!-- ROW DETAILS -->
      <!-- description -->
      <template #row-details="row">
        <b-card v-show="$store.getters['app/lgAndDown']">
          <b-row class="mb-2">
            <b-col class="mb-1" cols="12">
              <b-form-textarea
                id="offer-typologies-description"
                :placeholder="$t('common.description')"
                :value="$options.filters.trans(row.item.offerDescription)"
                class="hide-description-scroll"
                max-rows="4"
                no-resize
                plaintext
                rows="4"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
            {{ $t('action.hide_details') }}
          </b-button>
        </b-card>
      </template>

      <!-- CELL -->
      <!-- details -->
      <template #cell(details)="row">
        <b-form-checkbox v-show="$store.getters['app/lgAndDown']" id="offer-typologies-toggle-details" v-model="row.detailsShowing" @change="row.toggleDetails">
          <span>{{ row.detailsShowing ? $t('action.hide') : $t('action.show') }}</span>
        </b-form-checkbox>
      </template>
      <!--name-->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.item.name | trans }}</span>
      </template>
      <!-- typology -->
      <template #cell(typology)="data">
        {{ data.item.typology }}
      </template>
      <!-- offerDescription -->
      <template #cell(offerDescription)="data">
        <b-form-textarea
          id="offer-typologies-offer-description"
          :value="data.item.offerDescription[lang]"
          class="hide-description-scroll"
          max-rows="2"
          no-resize
          placeholder=""
          plaintext
          rows="2"
        >
        </b-form-textarea>
      </template>
      <!-- selected -->
      <template #cell(selected)="data">
        <div class="d-flex justify-content-center"><b-form-checkbox v-model="data.item.selected" /></div>
      </template>
    </b-table>
    <!--FOOTER-->
    <div class="mx-2 mb-2">
      <app-data-table-bottom
        :items-length="numberOfItemsPerPage"
        :page="page"
        :per-page="numberOfItemsPerPage"
        :total-count="totalItems"
        table-name="offer-typologies-table"
        @pagination="page = $event"
      />
    </div>
    <div class="my-2">
      <app-form-multiple-actions
        :disabled="selection || offerTypologiesLoading"
        :next="selection"
        :save="offerEdition"
        :save-next="!selection && offerEdition"
        previous
        @click:previous="$router.push(`/offer/${offerId}/informations/${offerEditionMode}`)"
        @click:save="submitOfferTypologies()"
        @click:save-next="submitOfferTypologies(true)"
        @click:next="$router.push(`/offer/${offerId}/commission/${offerEditionMode}`)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { fetchOfferTypologiesRequest, patchOfferTypologyRequest } from '@/request/globalApi/requests/offerRequests'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormMultipleActions from '@/components/AppFormMultipleActions.vue'

import { heightFade } from '@core/directives/animations'

import { isEqual } from 'lodash'

export default {
  name: 'OfferPartTypologies',

  components: {
    AppDataTableBottom,
    AppFormMultipleActions,
  },

  directives: {
    'height-fade': heightFade,
  },

  props: {
    // offer edition should be false on show mode
    offerEdition: {
      type: Boolean,
      required: true,
    },
    // duplicate or edit, no add or show
    offerEditionMode: {
      type: String,
      default: 'show',
    },
    offerId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      offerTypologiesLoading: false,
      offerTypologies: {
        old: [],
        edited: [],
      },
      selection: false,
      mainTypology: {},
      numberOfItemsPerPage: 10,
      page: 1,
      sortBy: 'typology',
      isSortDirDesc: false,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang', 'dataTable']),
    ...mapState('organizationConfiguration', ['organizationTypologies', 'organizationTypologiesLoading']),
    ...mapGetters('offerTypology', ['offerTypologiesById']),
    ...mapGetters('organizationConfiguration', ['optionableOrganizationTypologies']),
    ...mapGetters('offerInformation', ['offerReferenceById']),
    ...mapFields('offerTypology', ['typologiesChanged']),
    ...mapFields('offerInformation', ['mainTypologiesChanged']),

    offerTypologiesFields() {
      const fields = [
        { key: 'name', sortable: true },
        { key: 'typology', sortable: true },
        {
          key: this.offerEdition ? 'selected' : '',
          label: this.offerEdition ? this.$t('common.selected') : '',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ]
      if (this.$store.getters['app/lgAndDown']) {
        fields.unshift({ key: 'details', label: this.$t('common.details') })
      } else {
        fields.splice(2, 0, { key: 'offerDescription', label: this.$t('offer.typology.description') })
      }
      return fields
    },

    typologies() {
      return this.offerEdition ? this.offerTypologies.edited : this.offerTypologies.edited.filter(typology => typology.selected === true)
    },

    totalItems() {
      return this.typologies.length
    },
  },

  watch: {
    'offerTypologies.edited': {
      handler() {
        this.selection = isEqual(this.offerTypologies.edited, this.offerTypologies.old)
      },
      deep: true,
    },
    mainTypologiesChanged: {
      handler(value) {
        if (value) this.fetchOfferTypologies(true)
      },
    },
  },

  mounted() {
    this.numberOfItemsPerPage = this.dataTable.perPage
    this.fetchOrganizationConfigurationTypologies()
    this.fetchOfferTypologies(true)
  },

  methods: {
    ...mapActions('organizationConfiguration', ['fetchOrganizationConfigurationTypologies']),
    ...mapActions('offerTypology', ['setOfferTypologiesShowed', 'updateOfferTypologiesShowed']),

    tbodyRowClass(item) {
      if (item && item.selected) return ['b-table-row-selected', 'cursor-pointer']
      return ['cursor-pointer']
    },

    rowClicked(item) {
      if (item.selected) {
        this.$set(item, 'selected', false)
      } else {
        this.$set(item, 'selected', true)
      }
    },

    selectAllRows() {
      this.offerTypologies.edited.forEach(typo => {
        typo.selected = true
      })
      this.$refs['offer-typologies-table'].refresh()
    },

    clearSelected() {
      this.offerTypologies.edited.forEach(typo => {
        typo.selected = false
      })
      this.$refs['offer-typologies-table'].refresh()
    },

    resetOffer() {
      this.offerTypologies.edited = this._cloneDeep(this.offerTypologies.old)
      this.$refs['offer-typologies-table'].refresh()
    },

    mergeTypologies(offerTypologies) {
      const cleanedOfferTypologies = this._cloneDeep(offerTypologies)
      // separate main typology
      const mainTypologyIndex = cleanedOfferTypologies.findIndex(offerTypology => offerTypology.main === true)
      if (mainTypologyIndex !== -1) {
        this.mainTypology = cleanedOfferTypologies[mainTypologyIndex]
        cleanedOfferTypologies.splice(mainTypologyIndex, 1)
      }

      // merge organisation typologies and offer typologies selected
      const mergedTypologies = this._cloneDeep(this.optionableOrganizationTypologies)
      mergedTypologies.forEach((typology, index) => {
        if (typology.userType !== this.mainTypology.userType) {
          mergedTypologies.splice(index)
        } else {
          typology.selected = false
          typology.id === this.mainTypology.id && mergedTypologies.splice(index, 1)
          const matchedTypology = cleanedOfferTypologies.find(offerTypology => offerTypology.id === typology.id)
          matchedTypology && mergedTypologies.splice(index, 1, matchedTypology)
        }
      })

      // save typologies
      this.offerTypologies.edited = this._cloneDeep(mergedTypologies)
      this.offerTypologies.old = this._cloneDeep(this.offerTypologies.edited)
    },

    submitOfferTypologies(next) {
      this.offerTypologiesLoading = true
      const typologiesIds = []
      this.offerTypologies.edited.forEach(typology => {
        typology.selected && typologiesIds.push(typology.id)
      })

      patchOfferTypologyRequest(this.offerId, { typologiesIds }, this.offerReferenceById({ id: this.offerId, lang: this.lang }))
        .then(() => {
          this.fetchOfferTypologies(true)
          this.typologiesChanged = true
          this.$emit('offerEdited')
        })
        .then(() => {
          next && this.$router.push(`/offer/${this.offerId}/commission/${this.offerEditionMode}`)
        })
        .finally(() => {
          this.offerTypologiesLoading = false
        })
    },

    fetchOfferTypologies(refresh) {
      const typologies = this.offerTypologiesById(this.offerId)
      if (typologies && !refresh) {
        this.mergeTypologies(typologies)
      } else {
        this.offerTypologiesLoading = true
        fetchOfferTypologiesRequest(this.offerId)
          .then(response => {
            const newTypologies = []
            // TODO remove transform response
            response.data.forEach(typology => {
              const newTypology = { ...typology.typology, main: typology.main }
              // set selected
              newTypology.selected = true
              newTypologies.push(newTypology)
            })
            !refresh && this.setOfferTypologiesShowed({ id: this.offerId, typologies: newTypologies })
            refresh && this.updateOfferTypologiesShowed({ id: this.offerId, typologies: newTypologies })

            this.mergeTypologies(newTypologies)
          })
          .finally(() => {
            this.mainTypologiesChanged = false
            this.offerTypologiesLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
.hide-description-scroll::-webkit-scrollbar {
  display: none;
}
.top-row-unerline {
  border-bottom: 2px solid #d6b76d !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
