import CURRENCIES from '@/constants/currency'
import store from '@/store/store'

const getCurrency = (isLongFormat = false) => {
  const appCurrency = store.state.appConfig.currency
  const currency = CURRENCIES[appCurrency]

  if (currency) {
    if (!isLongFormat) return currency.symbol
    return currency.long
  }
  return appCurrency
}

export default getCurrency
