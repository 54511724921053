import globalApi from '@/request/globalApi/globalApi'

const END_POINT = 'api/file'

export const postFileRequest = (temporaryFile, message, dontAlertUser) => globalApi.post(END_POINT, temporaryFile, {
  message,
  dontAlertUser,
  headers: { 'Content-Type': 'multipart/form-data' },
})

//  WARNING form data only accept post methode
export const putFileRequest = (id, temporaryFile, message) => globalApi.post(`${END_POINT}/${id}`, temporaryFile, {
  message,
  headers: { 'Content-Type': 'multipart/form-data' },
})
