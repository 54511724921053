<template>
  <div id="offer-subscribers">
    <div class="m-2">
      <h3 class="mb-2">
        {{ $tc('offer.subscriber.title', 0) }}
      </h3>
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" lg="1" class="d-flex align-items-center justify-content-start mb-1 mb-lg-0">
          <b-form-select
            v-model="paginate.numberOfItemsPerPage"
            v-b-tooltip.hover.top="$t('action.per_page')"
            :options="dataTable.parPageOption"
            :disabled="offerSubscribersLoading"
            class="per-page-selector"
            @change="fetchOfferSubscribers(true)"
          />
        </b-col>
      </b-row>
    </div>
    <!-- TABLE -->
    <b-table
      ref="offer-subscribers-table"
      class="position-relative table-dropdown-action"
      primary-key="id"
      :items="offerSubscribers"
      :busy="offerSubscribersLoading"
      :fields="offerSubscribersFields"
      :empty-text="$t('common.no_record_found')"
      :sort-by="dataTableSort.sortBy"
      :sort-desc="dataTableSort.sortDesc"
      no-local-sorting
      no-sort-reset
      hover
      show-empty
      responsive
      @sort-changed="updateSort"
    >
      <!-- LOADING -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <!-- CELL -->
      <!--legalName-->
      <template #cell(legalName)="data">
        <span> {{ data.item.legalName }} </span>>
      </template>
      <!-- subscriptionStartAt -->
      <template #cell(subscriptionStartAt)="data">
        <span class="text-nowrap">
          {{
            new Date(data.item.subscriptionStartAt).toLocaleDateString($i18n.locale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            })
          }}
        </span>
      </template>
      <!-- subscriptionEndAt -->
      <template #cell(subscriptionEndAt)="data">
        <span class="text-nowrap">
          {{
            new Date(data.item.subscriptionEndAt).toLocaleDateString($i18n.locale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            })
          }}
        </span>
      </template>
    </b-table>
    <!--PAGINATION-->
    <div class="mx-2">
      <app-data-table-bottom
        table-name="offer-subscribers"
        :page="paginate.page"
        :per-page="paginate.numberOfItemsPerPage"
        :total-count="pagination.totalItems"
        @pagination=";(paginate.page = $event), fetchOfferSubscribers(true)"
      />
    </div>
    <div class="my-2">
      <app-form-multiple-actions previous @click:previous="$router.push(`/offer/${offerId}/subscription/${offerEditionMode}`)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import { fetchOfferSubscribersRequest } from '@/request/globalApi/requests/offerRequests'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppFormMultipleActions from '@/components/AppFormMultipleActions.vue'

export default {
  name: 'OfferPartSubscribers',

  components: {
    AppDataTableBottom,
    AppFormMultipleActions,
  },

  props: {
    offerId: {
      type: Number,
      default: null,
    },
    offerEditionMode: {
      type: String,
      default: 'show',
    },
  },

  data() {
    return {
      offerSubscribersLoading: false,
      offerSubscribers: [],

      pagination: { totalItems: 0 },
      paginate: { page: 1, numberOfItemsPerPage: 10 },
      order: {
        legalNameOrder: 'asc',
      },
      dataTableSort: {
        sortBy: 'legalName',
        sortDesc: false,
      },
    }
  },

  computed: {
    ...mapState('appConfig', ['dataTable']),
    ...mapGetters('offerSubscriber', ['offerSubscribersById']),

    offerSubscribersFields() {
      return [
        { key: 'legalName', label: this.$t('offer.subscriber.legalName'), sortable: true },
        { key: 'subscriptionStartAt', label: this.$t('offer.subscriber.subscriptionStartAt'), sortable: true },
        { key: 'subscriptionEndAt', label: this.$t('offer.subscriber.subscriptionEndAt') },
      ]
    },
  },

  methods: {
    ...mapActions('offerSubscriber', ['setOfferSubscribersShowed', 'updateOfferSubscribersShowed']),

    fetchOfferSubscribers(refresh) {
      const subscribers = this.offerSubscribersById(this.offerId)
      if (subscribers && !refresh) {
        this.offerSubscribers = subscribers
      } else {
        this.offerSubscribersLoading = true
        fetchOfferSubscribersRequest(this.offerId, {
          ...this.paginate,
          ...this.order,
        })
          .then(response => {
            this.pagination.totalItems = response.data.totalItems
            const { subscribers } = response.data
            this.offerSubscribers = subscribers
            !refresh && this.setOfferSubscribersShowed({ id: this.offerId, subscribers })
            refresh && this.updateOfferSubscribersShowed({ id: this.offerId, subscribers })
          })
          .finally(() => {
            this.offerSubscribersLoading = false
          })
      }
    },

    updateSort(e) {
      this.dataTableSort.sortBy = e.sortBy
      this.dataTableSort.sortDesc = e.sortDesc
      this.order = {}
      this.order[`${e.sortBy}Order`] = e.sortDesc ? 'desc' : 'asc'
      this.fetchOfferSubscribers(true)
    },
  },

  mounted() {
    this.paginate.numberOfItemsPerPage = this.dataTable.perPage
    this.fetchOfferSubscribers()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
</style>
