<template>
  <div>
    <b-row no-gutters>
      <div v-if="$store.getters['app/mdAndUp']" class="d-flex align-items-start justify-content-start mr-2">
        <!-- WARNING hard hack for use router -->
        <b-pagination-nav
          :link-gen="linkGen"
          :page-gen="pageGen"
          :number-of-pages="components.length"
          use-router
          class="d-none"
          @input="
            page => {
              defineComponent(components[page - 1].name)
            }
          "
        />

        <b-nav tabs vertical>
          <b-nav-item
            v-for="cpt in components"
            :key="cpt.name"
            :to="`/offer/${$route.params.id}/${cpt.to}/${offerEditionMode}`"
            exact-active-class="active"
            class="nav-vertical-bar"
          >
            {{ $tc(cpt.text, 0) }}
          </b-nav-item>
        </b-nav>
      </div>
      <b-col v-else>
        <b-pagination-nav
          :link-gen="linkGen"
          :page-gen="pageGen"
          :number-of-pages="components.length"
          use-router
          align="center"
          prev-class="pr-n2"
          first-number
          last-number
          @input="
            page => {
              defineComponent(components[page - 1].name)
            }
          "
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination-nav>
      </b-col>

      <b-col>
        <b-card no-body>
          <router-view></router-view>
          <component
            :is="component"
            :offer-edition="offerEdition"
            :offer-edition-mode="offerEditionMode"
            :offer-id="parseInt($route.params.id)"
            :subscribers-number="parseInt($route.params.subscribersNumber)"
            @offerEdited="offerEdited = true"
          ></component>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import OfferPartInformations from './OfferPartInformations.vue'
import OfferPartCommission from './OfferPartCommission.vue'
import OfferPartTypologies from './OfferPartTypologies.vue'
import OfferPartSubscribers from './OfferPartSubscribers.vue'
import OfferPartSubscription from './OfferPartSubscription.vue'

export default {
  name: 'OfferManager',
  components: {
    OfferPartInformations,
    OfferPartCommission,
    OfferPartTypologies,
    OfferPartSubscribers,
    OfferPartSubscription,
  },

  data() {
    return {
      offerEdited: false,
      offerEdition: false,
      offerEditionMode: 'show',
      component: 'OfferPartInformations',
      components: [
        { name: 'OfferPartInformations', text: 'offer.information.title', to: 'informations' },
        { name: 'OfferPartTypologies', text: 'offer.typology.title', to: 'typologies' },
        { name: 'OfferPartCommission', text: 'offer.commission.title', to: 'commission' },
        { name: 'OfferPartSubscription', text: 'offer.subscription.title', to: 'subscription' },
        { name: 'OfferPartSubscribers', text: 'offer.subscriber.title', to: 'subscribers' },
      ],
    }
  },

  mounted() {
    let editionMode = this.$route.matched[2].path.replace(`${this.$route.matched[1].path}/`, '')
    if (!editionMode) {
      editionMode = this.$route.params.editionMode
    }

    switch (editionMode) {
      case 'show':
        this.offerEdition = false
        break
      case 'edit':
        this.offerEditionMode = 'edit'
        this.offerEdition = true
        break
      case 'duplicate':
        this.offerEditionMode = 'duplicate'
        this.offerEdition = true
        break
      default:
        this.offerEdition = false
        break
    }
  },

  methods: {
    ...mapActions('offer', ['resetState', 'fetchOffers']),

    defineComponent(component) {
      this.component = component
    },
    linkGen(pageNum) {
      return `/offer/${this.$route.params.id}/${this.components[pageNum - 1].to}/${this.offerEditionMode}`
    },
    pageGen(pageNum) {
      return this.$tc(this.components[pageNum - 1].text, 0)
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].name !== 'offers') this.resetState()
    if (this.offerEdited) this.fetchOffers(true)
    next()
  },
}
</script>
<style lang="scss" scoped>
.nav-vertical-bar {
  .nav-link {
    justify-content: end;
    margin-bottom: 0;
    &:after {
      transform: rotate(90deg) translate3d(0, 150%, 0);
      left: 70%;
    }
    &.active {
      &:after {
        left: auto;
        right: -1.4rem;
        transform: rotate(90deg) translate3d(0, 225%, 0);
        top: 1.25rem;
        width: 2.14rem;
      }
    }
  }
}
</style>
