export default {
  methods: {
    percentageDecrease(price, percent) {
      return price * (1 - percent / 100)
    },

    percentageIncrease(price, percent) {
      return price * (1 + percent / 100)
    },
  },
}
